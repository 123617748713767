import React from "react";
import CheckboxFields from "../../shared/fields/CheckboxFields";

function ChatSettings() {
  return (
    <CheckboxFields
      key="chat"
      name="chat"
      label={I18n.t(`js.groups.form.chat`)}
      options={[
        { value: "enabled", label: "js.groups.form.chat_enabled" },
        { value: "disabled", label: "js.groups.form.chat_disabled" },
      ]}
    />
  );
}

export default ChatSettings;
