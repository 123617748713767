import React, { ReactNode, Children } from "react";

interface MiscSettingsProps {
  settings: ReactNode[];
}

function MiscSettings({ settings }: MiscSettingsProps) {
  return (
    <>
      <h3>{I18n.t("js.groups.edit.misc_settings")}</h3>
      {Children.map(settings, (setting) => (
        <>{setting}</>
      ))}
    </>
  );
}

export default MiscSettings;
